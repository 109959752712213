export const clientNameFooter = "SHANTI CLUB";
export const titleMain = "";
export const subtitleMain = "";

export const aspectRatioLogoAbout = '1'; // width/height
export const aspectRatioLogoMain = '1'; // width/height

export const borderRadiusButtons = '30px';

export const nomeFornecedorCompleto = "Shanti Club";
export const nomeFornecedor = "Shanti Club";
export const artigo = "o";
export const artigoCaps = "O";

export const footerSocialData = [
  {
    type: "material-community",
    name: "instagram",
    link: "https://www.instagram.com/lykaribeiro/",
    profile: "@lykaribeiro"
  },
  {
    type: "material-community",
    name: "youtube",
    link: "https://www.youtube.com",
  },

  {
    type: "material-community",
    name: "whatsapp",
    link: "https://wa.me/message/DWUTKVCRFILZC1",
    number: "(11) 99994-4359"
  },
];

export const drawerClosedLinks = [
  {
    title: "Fale Comigo",
    link: "https://wa.me/message/DWUTKVCRFILZC1",
  },
  {
    title: "Tiktok",
    link: "https://www.tiktok.com/@lyka.ribeiro",
  },
];

export const linkAppleStore = null
export const linkPlayStore = null

export const hasOutsideAboutPage = false;
export const drawerOpenedLinks = [];